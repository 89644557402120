import React from 'react';
import "./style.scss";


function SectionHeader({title}) {
  return (
    <h6 className="section-header">
      {title}
    </h6>
  );
}

export default SectionHeader;